import React, { useState } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import axios from "axios";
import config from "../../../../config/config.json";
import swal from "sweetalert";
import { apiCall } from "../../../../_services/apiCall";

export default function CreateMeter(props) {
  const [companyId, setCompanyId] = useState();
  const [meterId, setMeterId] = useState();
  const [unit, setUnit] = useState(1);
  const [process, setProcess] = useState();
  const [error, setError] = useState("");
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("currentUser"))
  );

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!meterId || !unit || !process) {
      setError("All fields are required.");
      return;
    }
    const data = {
      meter_id: meterId,
      process: process,
      unit: +unit,
      company_id: localStorage.getItem("user_temp_id"),
    };
    const {isSuccess} = await apiCall(`${config.API_URL}createMeterId`,{},data,"POST");
    if(isSuccess){
      window.location.href = config.baseURL+"/#/generator"
    }
    // axios
    //   .post(`${config.API_URL}createMeterId`, data, {
    //     headers: {
    //       Authorization: `Bearer ${token?.data?.token}`,
    //       Accept: "application/json",
    //     },
    //   })
    //   .then((response) => {
    //     setError("");
    //     swal({
    //       icon: "success",
    //       title: "Success",
    //       text: response.data.customMessage,
    //       timer: 2000,
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error, "error");
    //   });
  };
  return (
    <div>
      <Header />
      <Sidebar dataFromParent={props.location.pathname} />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="container-fluid">
            <section className="d_text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="business_detail">
                          <div className="">
                            <div className="saved_cards">
                              <form name="form">
                                <div className="business_detail">
                                  <div className="heading">
                                    <h4>Add Source</h4>
                                  </div>
                                  <hr className="line"></hr>
                                  <div className="row">
                                    <div className="col-lg-6 col-xs-6">
                                      <div className="form-group pb-3">
                                        <label
                                          htmlFor="industryType"
                                          className="mb-2"
                                        >
                                          Source id
                                        </label>
                                        <input className="form-control py-3" value={meterId} onChange={(e) =>{
                                          setMeterId(e.target.value);
                                        }}/>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-6">
                                      <div className="form-group pb-3">
                                        <label
                                          htmlFor="industryType"
                                          className="mb-2"
                                        >
                                          Source
                                        </label>
                                        <input className="form-control py-3" value={process} onChange={(e) => {
                                             setProcess(e.target.value);
                                        }}/>
                                      </div>
                                    </div>
                                    {/* <div className="col-lg-6 col-xs-6 form-group">
                                      <label
                                        htmlFor="question"
                                        className="mb-2"
                                      >
                                        unit
                                      </label>
                                      <input
                                        className="form-control py-3"
                                        value={unit}
                                        type="number"
                                        onChange={(e) => {
                                          setUnit(e.target.value);
                                        }}
                                      />
                                    </div> */}
                                  </div>
                                </div>
                                <div className="global_link mx-0 my-3">
                                  <button
                                    className="new_button_style"
                                    onClick={submitHandler}
                                  >
                                    ADD NOW
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
