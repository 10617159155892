import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar/sidebar";
import Header from "../header/header";
import Table from "react-bootstrap/Table";
import "../Sector_Question_Manage/control.css";
import { NavLink, useLocation } from "react-router-dom";
import { sectorQuestionService } from "../../_services/admin/global-controls/sectorQuestionService";
import axios from "axios";
import config from "../../config/config.json";
import { apiCall } from "../../_services/apiCall";
import { Button, Modal } from "react-bootstrap";

export const SectorQuestionFrameworkWise = () => {
  const location = useLocation();
  const [sectorQuestion, setSectorQuestion] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [mappingId, setMappingId] = useState(null);
  const [questionId, setQuestionId] = useState(null);
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("currentUser"))
  );

  const callApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getSectorQuestion`,
      {},
      { type: "ALL" },
      "GET"
    );
    if (isSuccess) {
      setSectorQuestion(data?.data);
      {
     
      }
    }
    // axios
    //   .get(
    //     `${
    //       config.API_URL
    //     }getSectorQuestion?type=ALL`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token?.data?.token}`,
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     setSectorQuestion(response?.data?.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // setSectorQuestion(response.questionTabs);
  };

  const deleteSectorQuestion = async (question_mapping_id, question_id) => {
    const body = {
      question_mapping_id: question_mapping_id,
      question_id: question_id,
    };
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}deleteSectorQuestion`,
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      callApi();
    }
  };
  const handleDeleteClick = (mappingId, questionId) => {
    setShowModal(true);
    setMappingId(mappingId);
    setQuestionId(questionId);
  };

  const handleYesClick = () => {
    deleteSectorQuestion(mappingId, questionId);
    setShowModal(false);
  };

  const handleNoClick = () => {
    setShowModal(false);
  };
  useEffect(() => {
    callApi();
  }, []);

  return (
    <div>
      <Sidebar dataFromParent={location.pathname} />
      <Header />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="container-fluid">
            <section className="d_text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="color_div_on framwork_2">
                      <div className="business_detail">
                        <div className="saved_cards">
                          <div className="business_detail">
                            <div className="heading_wth_text">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="heading">
                                  <h4>Sector Question List</h4>
                                </div>
                                <span className="global_link mx-0">
                                  <NavLink
                                    className="non_underline_link bold"
                                    to="/add_sector_questions"
                                  >
                                    <button
                                      className="new_button_style"
                                      variant="none"
                                      to="/add_sector_questions"
                                    >
                                      Add Sector Question
                                    </button>
                                  </NavLink>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="line"/>
                        <div className="table_f">
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr className="heading_color">
                                <th style={{ width: "5%" }}>Sr.</th>
                                <th>Framework</th>
                                <th>Topic</th>
                                <th>Kpi</th>
                                <th>Entity</th>
                                <th>Question Type</th>
                                <th>Question ID</th>
                                <th>Question Heading</th>
                                <th>Sector Question</th>
                                {/* <th style={{ width: "5%" }}>Edit </th> */}
                                <th style={{ width: "5%" }}>Delete </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sectorQuestion &&
                                sectorQuestion.map((item, key) => (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                
                                    <td>
                                      {item?.framework_title || <>-</>}
                                    </td>
                                    <td>{item?.topic_title || <>-</>}</td>
                                    <td>{item?.kpi_title || <>-</>}</td>
                                    <td>{item.entity}</td>
                                    <td>{item.questionType}</td>
                                    <td>{item.question_id}</td>
                                    <td>{item.title}</td>
                                    <td>{item.heading}</td>
                                    {item?.is_editable == "1" && (
                                      <td
                                        className="red"
                                        onClick={() =>
                                          handleDeleteClick(
                                            item.question_mapping_id,
                                            item.question_id
                                          )
                                        }
                                      >
                                        <NavLink
                                          to="#"
                                          className="view_c"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                          >
                                            <path
                                              fill="none"
                                              d="M0 0h24v24H0z"
                                            />
                                            <path d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM4 18.385L5.763 17H20V5H4v13.385zM13.414 11l2.475 2.475-1.414 1.414L12 12.414 9.525 14.89l-1.414-1.414L10.586 11 8.11 8.525l1.414-1.414L12 9.586l2.475-2.475 1.414 1.414L13.414 11z" />
                                          </svg>
                                        </NavLink>
                                      </td>
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal show={showModal} onHide={handleNoClick}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure to delete ?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleNoClick}>
              No
            </Button>
            <Button variant="primary" onClick={handleYesClick}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
