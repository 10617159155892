import React from "react";
import { useState } from "react";
import "./SectorQuestion.css";
export default function QuestionTypeTabSection({setFilterQuestionList,AllQuestionList,questionList}) {
  const [questionType, setQuestionType] = useState("All");
  return (
    <div className="question_type_filter__wrapper">
      <div
        className={`${questionType === 'All' ?"selected_question_type" :''}`}
        onClick={() => {
          setQuestionType("All");
          setFilterQuestionList(questionList);
        }}
      >
        All
      </div>
      <div
      className={`${questionType === 'quantitative' ?"selected_question_type" :''}`}
        onClick={() => {
          setQuestionType("quantitative");
          setFilterQuestionList(AllQuestionList?.quantitative);
        }}
      >
        Quantitative
      </div>
      <div
        className={`${questionType === 'tabular_question' ?"selected_question_type" :''}`}
        onClick={() => {
          setQuestionType("tabular_question");
          setFilterQuestionList(AllQuestionList?.tabular_question);
        }}
      >
        Tabular Question
      </div>
      <div
        className={`${questionType === 'qualitative' ?"selected_question_type" :''}`}
        onClick={() => {
          setQuestionType("qualitative");
          setFilterQuestionList(AllQuestionList?.qualitative);
        }}
      >
        Qualitative
      </div>
      <div
     className={`${questionType === 'yes_no' ?"selected_question_type" :''}`}
        onClick={() => {
          setQuestionType("yes_no");
          setFilterQuestionList(AllQuestionList?.yes_no);
        }}
      >
        Close Range
      </div>
      <div
       className={`${questionType === 'quantitative_trends' ?"selected_question_type" :''}`}
        onClick={() => {
          setQuestionType("quantitative_trends");
          setFilterQuestionList(AllQuestionList?.quantitative_trends);
        }}
      >
        Quantitative Trends
      </div>
    </div>
  );
}
