import React from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { NavLink, useLocation,useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./supplier_assessment.css";
import Table from "react-bootstrap/Table";

export default function Supplier_assessment(props) {
  const location = useLocation();

  return (
    <div>
      <Sidebar dataFromParent={location?.pathname} />
      <Header />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="container-fluid">
            <section className="d_text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12 color_div_on framwork_2">
                    <div className="new__assesment_btn__wrapper"></div>
                    <div className="heading heading_wth_text">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>Supplier Assesment List</h4>
                        <Button style={{ background: "#1f9ed1",  border: "none", padding: "10px", }}
                          onClick={()=>{
                            props.history.push('/supplier_assessment/create_new_assessement')
                          }} 
                        > New Supplier Assessment </Button>
                      </div>
                    </div>
                    <hr className="line"/>
                    <div>
                    <div className="table_f manage-detail admin-risk-table table-responsive">
                        <Table striped bordered hover size="sm">
                            <thead>
                                  <tr className="heading_color">
                                    <th>ID</th>
                                    <th scope="col">Assessment Name</th>
                                    <th scope="col">Date</th>
                                    {/* <th scope="col">EMAIL</th>
                                    <th scope="col">INDUSTRY</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                {/* {suppliers?.map((item,key) => ( */}
                                  <tr >
                                    <td>1</td>
                                    <td>Assessment Name 1</td>
                                    <td>03-08-2023</td>
                                  </tr>
                                  <tr >
                                    <td>1</td>
                                    <td>Assessment Name 1</td>
                                    <td>03-08-2023</td>
                                  </tr>
                                  <tr >
                                    <td>1</td>
                                    <td>Assessment Name 1</td>
                                    <td>03-08-2023</td>
                                  </tr>
                                {/* ))} */}
                                </tbody>
                        </Table>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
