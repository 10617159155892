import config from "../../../config/config.json";
import { getStore } from "../../../utils/UniversalFunction";
import swal from "sweetalert";

export const topicService = {
  getTopics,
  updateSubscriptionStatus,
  createTopic,
  deleteTopic,
  updateTopic,
  getTopicByID,
  getTopicsAccordingToFramework,
  getKpisAccordingToFrameworkAndTopics
};

async function getTopicByID(path) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}getTopicByID/${path}`,
    headersWithAuthNew("GET", headerSet.data.token)
  );
  const data = await response.json();
  return data;
}

async function updateTopic(userData) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}updateTopic`,
    headersWithAuthBody("POST",userData, headerSet.data.token)
  );
  const data = await response.json();
  handleResponse(data);
}

async function deleteTopic(uuid) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}deleteTopic/${uuid}`,
    headersWithAuthNew("DELETE", headerSet.data.token)
  );
  const data = await response.json();
  handleResponse(data);
}

async function getTopics(type) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}getTopic?type=${type}`,
    headersWithAuthNew("GET", headerSet.data.token)
  );
  const data = await response.json();
  return data;
}

async function getTopicsAccordingToFramework(frameworkId) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}getTopic?type=ADD_DATA_AVAILABLE&company_id=${getStore('user_temp_id')}&framework_id=${frameworkId}`,
    headersWithAuthNew("GET", headerSet.data.token)
  );
  const data = await response.json();
  return data;
}

async function getKpisAccordingToFrameworkAndTopics(topicId) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.ADMIN_API_URL}getKpisAccordingToTopics?topic_mapping_id=${topicId}&company_id=${localStorage.getItem('user_temp_id')}`,
    headersWithAuthNew("GET", headerSet.data.token)
  );
  const data = await response.json();
  return data;
}


async function createTopic(userData) {
    let headerSet = getStore("currentUser");
    userData.company_id=getStore('user_temp_id')
    const response = await fetch(
      `${config.API_URL}createTopic`,
      headersWithAuthBody("POST",userData, headerSet.data.token)
    );
    const data = await response.json();
    handleResponse(data);
  }


async function updateSubscriptionStatus(userData) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}billing/subscription/update`,
    headersWithAuthBody("POST",userData, headerSet.data.token)
  );
  const data = await response.json();
  handleResponse(data);
}

function headersWithAuthBody(method,data, token) {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let raw = JSON.stringify(data);

  let requestOptions = {
    method: method,
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return requestOptions;
}

function headersWithAuthNew(method, token) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  var requestOptions = {
    method: method,
    headers: myHeaders,
    redirect: "follow",
  };
  return requestOptions;
}

// function headersWithAuth(method, userData, auth) {
//   return {
//     method: method,
//     headers: auth,
//     body: JSON.stringify(userData),
//   };
// }

function handleResponse(response) {
  return response.statusCode === 200
    ? successAlert(response.customMessage, "", "success")
    : alert(response.customMessage, "Please try again later..!", "error");
}

function alert(message, message2, statusCode) {
  swal(message, message2, statusCode);
  return false;
}

function successAlert(message, message2, statusCode) {
  swal({
    icon: 'success',  
    title: message2, 
    text: message,
    timer: 2000
    })
  return true;
}
