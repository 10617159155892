import React, { Component } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { NavLink } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import config from "../../../../config/config.json";
import { DataContext } from "../../../../contextApi";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";

const authValue = JSON.parse(localStorage.getItem("currentUser"));
export default class home extends Component {
  state = {
    frameworkValue: [],
    updatedValue: [],
    selecedValue: [],
    topicsData: [],
    selectedMappingIds: [],
    kpiData:[],
    //new state
    selectedFramework: [],
    selectedTopics: [],
    initialFtechTopicData: false,
    showButton:false,
    buttonText:'Next',
    show: false,
    close: false,
    kpiData: [],
    slectedkpiList: [],
    checked: false,
    selectedKpiMappingIds: [this.context?.selectedKpiMappingData],
  };
  static contextType = DataContext;

  componentDidMount = () => {
    this.fetchFrameworkApi();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.frameworkValue &&
      this.state.frameworkValue.length !==
        (prevState.frameworkValue && prevState.frameworkValue.length)
    ) {
      this.fetchStoredData();
    }
    if (
      this.state.selectedFramework &&
      this.state.selectedFramework.length !==
        (prevState.selectedFramework && prevState.selectedFramework.length)
    ) {
      if (
        this.state.selectedFramework &&
        this.state.selectedFramework.length > 0
      ) {
        const frameworkIds = this.state.selectedFramework.map(({ id }) => id);
        if (
          this.state.initialFtechTopicData &&
          prevState.initialFtechTopicData === false
        ) {
          this.fetchTopicData(frameworkIds, true);
        } else {
          this.fetchTopicData(frameworkIds);
        }
      }
    }
  };

  fetchFrameworkApi = () => {
    const authValue = JSON.parse(localStorage.getItem("currentUser"));
    axios
      .get(
        `${config.API_URL}getFramework?type=ALL`,
        {
          headers: {
            Authorization: `Bearer ${authValue?.data?.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        this.setState({
          frameworkValue: response?.data?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchTopicData = (framworkIds, fetchInitialData) => {
    if (framworkIds && framworkIds.length > 0) {
      const authValue = JSON.parse(localStorage.getItem("currentUser"));
      axios
        .get(
          `${
            config.API_URL
          }admin/getTopic?type=ESG&framework_ids=${JSON.stringify(framworkIds)}`,
          {
            headers: {
              Authorization: `Bearer ${authValue?.data?.token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.setState({ topicsData: response?.data?.data });
          if (
            fetchInitialData &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const filterSelctedTopics = response.data.data.filter((topic) => {
              return this.context.selectedTopicsData.find((value) => {
                return value == topic.id;
              });
            });
            const selectedTopicIds =
              (filterSelctedTopics &&
                filterSelctedTopics.length > 0 &&
                filterSelctedTopics.map(({ id }) => id)) ||
              [];
            this.setState({ selectedTopics: filterSelctedTopics });
            this.context.setSelectedTopicsData(selectedTopicIds);
          }
          this.getKpiData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  saveSelectedData = () =>{
    const authValue = JSON.parse(localStorage.getItem("currentUser"));
    const data = {
      data: {
        kpi_id: this.context?.SelectedKpiData,
        topic_id: this.context?.selectedTopicsData,
        framework_id: this.context?.selectedFrameworkData,
      },
      company_id: localStorage.getItem("user_temp_id"),
    };
    axios
      .post(`${config.API_URL}saveDataCompanyWise`, data, {
        headers: {
          Authorization: `Bearer ${authValue?.data?.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((data) => {
        console.log("data", data);
        // this.props.history.push("/sector_questions");
      })
      .catch((error) => console.log("error", error));
  }

 getKpiData = () =>{
  axios
  .get(
    `${config.API_URL}admin/getKpi?type=ESG&company_id=${localStorage.getItem(
      "user_temp_id"
    )}&topic_ids=${JSON.stringify(this.context.selectedTopicsData)}`,
    {
      headers: {
        Authorization: `Bearer ${authValue?.data?.token}`,
        "Content-Type": "application/json",
      },
    }
  )
  .then((response) => {
    console.log(response, "response");
    this.setState({ kpiData: response?.data?.data });
    if (
      response.data &&
      response.data.data &&
      response.data.data.length > 0
    ) {
      const filteredKPIs = response.data.data.filter((kpiObj) => {
        return this.context.SelectedKpiData.find((value) => {
          return value === kpiObj.id;
        });
      });
      if (filteredKPIs && filteredKPIs.length > 0) {
        const kpiIds = filteredKPIs.map(({ id }) => id);
        this.setState({ slectedkpiList: kpiIds });
        this.context.SelectedKpiData(kpiIds);
      }
    }
  })
  .catch((error) => {
    console.log(error);
  });
 }


 handleConfirm = () => {
  this.saveSelectedData();
  };

  
  fetchStoredData = () => {
    const authValue = JSON.parse(localStorage.getItem("currentUser"));
    axios
      .get(
        `${config.API_URL}/getDataCompanyWise?company_id=${localStorage.getItem(
          "user_temp_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${authValue?.data?.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response?.data?.data) {
          const data =
            JSON.parse(response?.data?.data?.framework_topic_kpi) || {};
          this.context.setSelectedKpiData(data?.kpi_id || []);
          this.context.setSelectedFrameworkData(data?.framework_id || []);
          this.context.setSelectedTopicsData(data?.topic_id || []);
          if (data && data.framework_id && data.framework_id.length > 0) {
            this.handleAutoselectedFramework(data.framework_id);
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  handleAutoselectedFramework = (selectedFrameworkMapping) => {
    const filteredFrameValue = this.state.frameworkValue.filter((obj) => {
      return selectedFrameworkMapping.find((value) => {
        return value === obj.id;
      });
    });
    if (filteredFrameValue && filteredFrameValue.length > 0) {
      this.setState((prevState) => {
        return {
          ...prevState,
          selectedFramework: filteredFrameValue,
          initialFtechTopicData: true,
        };
      });
      this.fetchTopicData();
    }
  };

  onSelectFrameworkHandler = (data) => {
    const frameworkIds = data && data?.map(({ id }) => id);
    this.context.setSelectedFrameworkData(frameworkIds);
    const selectedFrameworkMappingIds = data && data.map(({ id }) => id);
    this.context.setSelectedFrameworkMappingData(selectedFrameworkMappingIds);
    this.setState({
      selectedFramework: data || [],
    });
  };

  onRemoveFrameworkHandler = (data) => {
    //setp 1 if removed all data
    if (data && data.length === 0) {
      this.setState({
        selectedFramework: [],
        selectedTopics: [],
      });
      this.context.setSelectedFrameworkMappingData([]);
      this.context.setSelectedTopicsData([]);
      this.context.setSelectedKpiMappingData([]);
    } else {
      //Step 2 remove data from selectedFramewok and selecetd framework mapping ids
      this.onSelectFrameworkHandler(data);
      //Step 3 remove if selected topics and sellecte KPIs
      if (this.state.selectedTopics && this.state.selectedTopics.length > 0) {
        const filteredTopics = this.state.selectedTopics.filter((obj) => {
          return data.find((dataObj) => {
            return dataObj.id === obj.id;
          });
        });
        if (filteredTopics && filteredTopics.length > 0) {
          const topicMappingIds = filteredTopics.map(({ id }) => id);
          const topicIds = filteredTopics.map(({ id }) => id);
          this.setState({ selectedTopics: filteredTopics });
          // this.context.setSelectedTopicsData(topicMappingIds);
          this.context.setSelectedTopicsData(topicMappingIds);
        } else {
          this.setState({ selectedTopics: [] });
          this.context.setSelectedTopicsData([]);
          this.context.setSelectedKpiMappingData([]);
          // this.context.setSelectedTopicsData([]);
        }
      }
    }
  };

  onTopicChangeHandler = (event, topicObj) => {
    if (event.target.checked) {
      const selectedTopicData = [...this.state.selectedTopics, topicObj];
      this.setState({ selectedTopics: selectedTopicData });
      let ids = [];
      let mappingIds = [];
      selectedTopicData.forEach((data) => {
        ids.push(data?.id);
        // mappingIds.push(data?.id);
      });
      this.context.setSelectedTopicsData(ids);
      this.getKpiData();
      // this.context.setSelectedTopicsData(ids);
    } else {
      const selectedTopicData = this.state.selectedTopics.filter((topic) => {
        if (topic.id !== topicObj.id) {
          return topic;
        }
      });
      const filterSelectedMappingIds = this.context.selectedTopicsData.filter(
        (value) => value !== topicObj.id
      );
      const selectedTopicsIds =
        selectedTopicData &&
        selectedTopicData.length > 0 &&
        selectedTopicData.map(({ id }) => id);
      this.setState({ selectedTopics: selectedTopicData });
      // this.context.setSelectedTopicMappingData(filterSelectedMappingIds);
      this.context.setSelectedTopicsData(filterSelectedMappingIds);
      this.getKpiData();
    }
  };

  handleSelect = (event, kpiObj) => {
    if (event.target.checked) {
      const kpiIds = [...this.state.slectedkpiList, kpiObj?.id];
      const kpiMappingIds = [
        ...this.context.SelectedKpiData,
        kpiObj?.id,
      ];
      this.setState({
        slectedkpiList: kpiIds,
        selectedKpiMappingIds: kpiMappingIds,
      });
      this.context.setSelectedKpiData(kpiIds);
      this.context.setSelectedKpiMappingData(kpiMappingIds);
    } else {
      console.log("satte", this.state.selectedKpiMappingIds)
      const filteredIds = this.state.slectedkpiList.filter(
        (value) => value !== kpiObj.id
      );
      const filteredMappingIds = this.context.selectedKpiMappingData.filter(
        (value) => value !== kpiObj.id
      );
      console.log("filteredMappingIds", filteredMappingIds)
      this.setState({
        slectedkpiList: filteredIds,
        selectedKpiMappingIds: filteredMappingIds,
      });
      this.context.setSelectedKpiData(filteredIds);
      this.context.setSelectedKpiMappingData(filteredMappingIds);
    }
  };
  render() {
    return (
      <>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <h5 className="frame">
                          Please select which ESG reporting Framework you want
                          to use:
                        </h5>
                        <div className="Global">
                          <div className="row">
                            <div className="col-sm-12 col-xs-12">
                              <div className="border_box p-3">
                                <div className="wel_fel">
                                  <div className="row">
                                    <Multiselect
                                      placeholder={"Select Framework"}
                                      displayValue="title"
                                      options={this.state.frameworkValue}
                                      selectedValues={
                                        this.state.selectedFramework
                                      }
                                      onRemove={(e) => {
                                        this.onRemoveFrameworkHandler(e);
                                      }}
                                      onSelect={(e) => {
                                        this.onSelectFrameworkHandler(e);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      {this.state.topicsData.length > 0 && (
                        <div className="color_div_on framwork_2 mb-4">
                          <h5 className="frame">
                            Please select your focus areas:
                          </h5>
                          <div className="Global">
                            <div className="row">
                              <div className="col-sm-12 col-xs-12">
                                <div className="border_box p-3">
                                  <div className="wel_fel">
                                    <div className="row">
                                      {this.state.topicsData?.map(
                                        (data, index) => {
                                          let dataFlag = [
                                            ...this.context?.selectedTopicsData,
                                          ].some((value) => value == data?.id);
                                          return (
                                            <div
                                              key={index}
                                              className="col-xxl-6 col-lg-6 col-md-12 col-12"
                                            >
                                              <div className="Global_text">
                                                <div className="form-check form-check-inline clobal_check input-padding">
                                                  <input
                                                    className="form-check-input input_one "
                                                    name="frameworksUsed[]"
                                                    type="checkbox"
                                                    checked={
                                                      dataFlag ? true : false
                                                    }
                                                    onChange={(e) => {
                                                      this.onTopicChangeHandler(
                                                        e,
                                                        data
                                                      );
                                                    }}
                                                  />

                                                  <label
                                                    className="form-check-label label_one"
                                                    htmlFor={
                                                      "xcfgvldskfjgosdfg"
                                                    }
                                                  >
                                                    {data?.title}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {
                        // this.state?.kpiData?.length > 0 && 
                        <div className="row">
                        {this.state.kpiData.length > 0 && <div className="col-sm-12">
                          <div className="color_div_on framwork_2 mb-4">
                            <h5 className="frame">
                              Please select KPIs you want to use:
                            </h5>
                            <div className="Global">
                              <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                  <div className="border_box p-3">
                                    <div className="wel_fel">
                                      <div className="row">
                                        {this.state.kpiData.map((data, index) => {
                                          const dataFlag =
                                            this.context.SelectedKpiData &&
                                            this.context.SelectedKpiData.some(
                                              (value) =>
                                                value === data.id
                                            );
                                          return (
                                            <div
                                              key={index}
                                              className="col-xxl-6 col-lg-6 col-md-12 col-12"
                                            >
                                              <div className="Global_text">
                                                <div className="form-check form-check-inline clobal_check input-padding">
                                                  <input
                                                    className="form-check-input input_one "
                                                    // name="frameworksUsed[]"
                                                    type="checkbox"
                                                    checked={dataFlag}
                                                    onChange={(e) => {
                                                      this.handleSelect(e, data);
                                                    }}
                                                  />
                                                  <label
                                                    className="form-check-label label_one"
                                                    htmlFor={"xcfgvldskfjgosdfg"}
                                                  >
                                                    {data.title}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>}
                      </div>
                      
                      }
                      <div className="global_link">
                              {/* <span className="global_link">
                                <button className="link_bal_next"> Back </button>
                              </span> */}
                              <div className="save_global global_link">
                                <button
                                  className="page_save page_width"
                                  variant="none"
                                  onClick={() => {
                                    this.setState({ show: true });
                                    this.handleConfirm();
                                  }}
                                >
                                  {" "}
                                  Confirm{" "}
                                </button>
                              </div>
                              <Modal
                                show={this.state.show}
                                animation={true}
                                size="md"
                                className="modal_box"
                                shadow-lg="border"
                              >
                                <div className="modal-md">
                                  <Modal.Header className="pb-0">
                                    <Button
                                      variant="outline-dark"
                                      onClick={() => this.setState({ show: false })}
                                    >
                                      <i className="fa fa-times"></i>
                                    </Button>
                                  </Modal.Header>
                                  <div className="modal-body vekp pt-0">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="response">
                                          <h4>Response Saved!</h4>
                                          <p className="text-center my-3">
                                            {" "}
                                            To download your ESG Risk Report please
                                            complete the Sector Questions and
                                            download at the completion of that
                                            module.{" "}
                                          </p>
                                          <div className="global_link">
                                            <NavLink
                                              className="page_save page_width"
                                              to={"sector_questions"}
                                            >
                                              {" "}
                                              go to sector questions{" "}
                                            </NavLink>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}
