import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../img/Zais_logo.png";
import "../sidebar/common.css";
import { BehaviorSubject } from 'rxjs';
import { Col, Row } from "react-bootstrap";
import LoginImages from '../../img/login-image.jpg'

export default class Massage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  handleLoginClick = () => {
    let currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);

  };

  componentDidMount() {

  }
  render() {
    return (
      <div>
        <Row>
          <Col md={7}>
            <div className="login-left-panel">
              <img src={LoginImages} alt="" />
            </div>
          </Col>
          <Col md={5}>
            <section className="login">
              <div className="login_part">
                <div className="sing_log">
                  <div className="sing_one">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="text_sing mb-4">
                    <h4 className="Account">Verify your email address</h4>
                    <p className="massage">Your login credentials have been shared over your registered email.</p>

                    <p>If your are already Verified, then click on Login Button.</p>
                    <div className="global d-flex justify-content-center">
                      <NavLink onClick={this.handleLoginClick} className="new_button_style button_color w-100" to="/login">
                        Login
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Col>
          </Row>
      </div>
    );
  }
}
