/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {sweetAlert} from '../../../../utils/UniversalFunction';
import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import config from "../../../../config/config.json";
import "./supplier_management.css";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Pagination, Icon, Label } from "semantic-ui-react";
import { authenticationService } from "../../../../_services/authentication";
import { event } from 'jquery';
const currentUser = authenticationService.currentUserValue;

export default class supplier_management_form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      criteria: [props.location.aboutProps] ?? [],
      finalIds: [],
      submitted: false,
      csvsubmitted: false,
      firstName: "",
      lastName: "",
      email: "",
      companyName1: "",
      firstName1: "",
      lastName1: "",
      email1: "",
      companyName: "",
      companyIndustory: [],
      industry: "",
      industry1: "",
      industryList: [],
      addNewSupplier: false,
      addNewSupplierButton: true,
      selectedIds: [],
      supplier_name:"",
      industryId:"",
      industryTitle:"",
      supplier:[],
      inviteLen:1,
      inviteSuppliers:[],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeForSelect = this.handleChangeForSelect.bind(this);
    this.handleChangeForSelect1 = this.handleChangeForSelect1.bind(this);
    this.applySecondForm = this.applySecondForm.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
    this.handleSubmitForSearch = this.handleSubmitForSearch.bind(this);
    this.supplierSubmit = this.supplierSubmit.bind(this);
    this.formatDateTime = this.formatDateTime.bind(this);
    
  }
  formatDateTime(dateTimeString) {
    const dateObj = new Date(dateTimeString);
    const formattedDateTime = dateObj.toLocaleString();
    return formattedDateTime;
  }

  handleRowSelect = (id) => {
    const { selectedIds } = this.state;
    const index = selectedIds.indexOf(id);
    if (index > -1) {
      selectedIds.splice(index, 1);
    } else {
      selectedIds.push(id);
    }

    this.setState({ selectedIds });
    console.log(selectedIds,"selectedIds")
  };
  supplierSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const {
      selectedIds
    } = this.state;
    if (selectedIds.length>0 ) {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };
      axios
        .post(
          `${config.API_URL}inviteSupplier`,
          {
            supplier_ids:selectedIds,
          },
          { headers }
        )
        .then((response) => {
          sweetAlert('success',response.data.message);
          setTimeout(() => {
            const newLocal = "/supplier_management_detail";
            this.props.history.push(newLocal);
          }, 1000);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert('error',error.response.data.errorMessage);
          }
        });
    } 
    else {
      sweetAlert('error',"Please Select Supplier.");
    }
  };

  handleChangeForSelect(event) {
    const { value, industry } = event;
    this.setState({
      [industry]: value,
    });
  }

  handleChangeForSelect1(event) {
    const { value, industry1 } = event;
    this.setState({
      [industry1]: value,
    });
  }

  applySecondForm() {
    this.setState({
      addNewSupplier: true,
      addNewSupplierButton: false,
    });
  }
  handleChange(event) {

    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  
  updateMessage() {
    sweetAlert('info',"The Document will be uploaded within next 48 hours");	
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const {
      firstName,
      lastName,
      email,
      companyName,
      finalIds,
      industry,
      mobile,
    } = this.state;
    if (firstName && lastName && email && companyName ) {
      const headers = {
        Authorization: `Bearer ${currentUser.data.token}`,
        Accept: "application/json",
      };

      axios
        .post(
          `${config.API_URL}inviteSupplier`,
          {
            first_name:firstName,
            last_name:lastName,
            email:email,
            company_name:companyName,
            company_industry:industry.split("-")[0],
            company_industry_id:industry.split("-")[1],
            mobile:mobile
          },
          { headers }
        )
        .then((response) => {
          sweetAlert('success',response.data.message);
          setTimeout(() => {
            const newLocal = "/supplier_management_form";
            this.props.history.push(newLocal);
          }, 1000);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert('error',error.response.data.errorMessage);
          }
        });
    } 
    else {
      sweetAlert('error',"Please fill all input");
    }
  }

  handleSubmitForSearch = async (event) => {
    event.preventDefault();
    const { supplier_name } = this.state;
    console.log("supplier", supplier_name);
    try {
      const response = await axios.get(
        `${config.BASE_URL}searchSupplier?register_company_name=${supplier_name}`
      );
      const result = response.data;
      console.log(result, "result");
      this.setState({
        isLoaded2: true,
        supplier: result.data,
        inviteLen:result.data.length
      });
    } catch (error) {
      this.setState({
        isLoaded2: true,
        error2: error,
      });
    }
    this.setState({ supplier_name: "" });
  };
  

  emailValidator = (text) => {
    let reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (reg.test(text) === false) {
      this.setState({ emailError: "Please enter a valid email address" });
      return false;
    } else {
      this.setState({ email: text, emailError:"" });
    }
  };

  onFileChange = (event) => {
    this.setState({ csvsubmitted: true });
    let topicId = event.target.getAttribute("data-id");
    const formData = new FormData();

    // Update the formData object
    formData.append("csv", event.target.files[0], event.target.files[0].name);
    formData.append("supplier_management_criteria_ids", topicId);

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(config.OLD_API_URL + "supplierManagementWithCSV", formData, {
        headers,
      })
      .then((response) => {
        sweetAlert('success',response.data.message);	
        setTimeout(() => {
          const newLocal = "/supplier_management_detail";
          this.props.history.push(newLocal);
        }, 1000);
      })
      .catch(function (response) {
        // sweetAlert('error',response.data.message);
      });
  };

  componentDidMount(id) {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type: "supplier_management" }),
    };
    if (currentUser.data.role === "company_sub_admin") {
      fetch(
        config.API_URL + "getSupplierManagementAssignedUser",
        requestOptions
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (currentUser.data.role === "company_sub_admin") {
              let getStatus = result.data.length > 0 ? true : false;
              if (getStatus === false) {
                this.props.history.push("/supplier_management_detail");
              }
            }
            this.setState({
              isLoaded: true,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(
      config.API_URL +
        `getInvitedSuppliers`,
      {headers}
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            inviteSuppliers: result.data,
            totalCount: result.totalCount,
            pages: Math.floor(result.data.length / this.state.perPage),
            isEditableOrNot: result?.insertOrUpdate,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
    axios
      .post(
        config.API_URL + "getSupplierManagementCriteriaWithCondition",
        { ids: this.state.criteria },
        { headers }
      )
      .then((response) => {
        this.setState({ finalIds: response.data.data });
      })
      .catch((error)=> {
        // sweetAlert('error', error.response.data.message);
      });

      axios
      .get(config.API_URL + "getSupplierManagementIndustry", { headers })
      .then((response) => {
        this.setState({ industryList: response.data.data });
      })
      .catch((error) => {
        // sweetAlert('error', error.response.data.message);
      });    

    fetch(config.BASE_URL + "getCompanyIndustoryName")
    .then((res) => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded2: true,
          companyIndustory: result.companyIndustory,
        });
      },
      
      (error2) => {
        this.setState({
          isLoaded2: true,
          error2,
        });
      }
    );
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      companyName,
      submitted,
      industry,
      companyIndustory,
      mobile,
      supplier,
      inviteLen,
      inviteSuppliers
    } = this.state;
    return (
      <div>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div classNamTopicse="container-fluid">
                  <div className="row">
                  <div className="col-md-12" >
                  <form name="form">
                  <div className="search__section" style={{marginBottom:"5px"}}>
                    <div>
                      {/* Additional content can be added here */}
                    </div>
                    <div className="d-flex dropdown align-items-center justify-content-between">
                    <div>
                      <button
                        className='new_button_style'
                        type="submit"
                        onClick={this.handleSubmitForSearch}
                      >
                        Get Supplier
                      </button>
                      
                    </div>
                    <div className='d-flex align-items-center'>
                      <div>Search :</div>
                        <input
                          className='select_one_all '
                          type="text"
                          name="supplier_name"
                          value={this.state.supplier_name}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                  </div>
                  <div className="col-sm-12">
                  <div className="color_div_on framwork_2 mt-3">
                    <div className="business_detail">
                      <div className="saved_cards">
                        <div className="business_detail">
                          <div className="heading">
                            <div className="heading_wth_text">
                              <h4>
                                Invite Suppliers
                              </h4>
                            </div>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="table_f manage-detail admin-risk-table table-responsive">
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr className="heading_color">
                                <th>ID</th>
                                <th scope="col">COMPANY NAME</th>
                                <th scope="col">EMAIL</th>
                                <th scope="col">REQUEST DATE</th>
                                <th scope="col">INVITATION STATUS</th>
                              </tr>
                            </thead>
                            <tbody>
                            {inviteSuppliers?.map((item,key) => (
                              <tr key={key}>
                                <td>{key+1}</td>
                                <td>{item?.company_name}</td>
                                <td>{item?.email}</td>
                                <td>{this.formatDateTime(item?.request_date)}</td>
                                <td><span className="red bold">
                                {item?.status === 0 &&
                                  "REJECTED"}
                              </span>
                              <span className="green bold">
                                {item?.status === 2 &&
                                  "PENDING"}
                              </span></td>
                              </tr>
                            ))}
                            </tbody>
                          </Table>

                          <div className="Page navigation example">
                            <Pagination
                              className="mx-auto pagination"
                              defaultActivePage={1}
                              onPageChange={this.pageChange}
                              ellipsisItem={{
                                content: (
                                  <Icon name="ellipsis horizontal" />
                                ),
                                icon: true,
                              }}
                              firstItem={{
                                content: <Icon name="angle double left" />,
                                icon: true,
                              }}
                              lastItem={{
                                content: <Icon name="angle double right" />,
                                icon: true,
                              }}
                              prevItem={{
                                content: <Icon name="angle left" />,
                                icon: true,
                              }}
                              nextItem={{
                                content: <Icon name="angle right" />,
                                icon: true,
                              }}
                              totalPages={Math.ceil(
                                this.state.totalCount / this.state.limit
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                    <div className="col-sm-12" style={{marginTop:"20px"}}>
                    {supplier.length>0 &&(<div className="requirem">
                    <div className="saved_cards">
                    <div className="business_detail">
                      <div className="heading">
                        <div className="heading_wth_text">
                          <h4 className="font-heading">
                            Supplier Management
                          </h4>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="table_f manage-detail admin-risk-table table-responsive">
                    <table className="table table-striped table-bordered table-hover">
                      <thead>
                        <tr className="heading_color">
                          <th>SELECT</th>
                          <th>SUPPLIER NAME</th>
                          <th scope="col">SUPPLIER COMPANY</th>
                          <th scope="col">EMAIL</th>
                          <th scope="col">INDUSTRY</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supplier?.map((row) => (
                          <tr key={row.id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={this.state.selectedIds.includes(row.id)}
                                onChange={() => this.handleRowSelect(row.id)}
                              />
                            </td>
                            <td>{row.first_name+" "+row.last_name}</td>
                            <td>{row.register_company_name}</td>
                            <td>{row.email}</td>
                            <td>{row.company_industry}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div style={{display: "flex", justifyContent: "flex-end"}}><button style={{background:"#1f9ed1", border:"none" ,borderRadius:"5px",height:"50px", color:"#fff", fontWeight:"bold", padding:"10px 20px",display: "flex",alignItems:"center",justifyContent:"flex-end"}} type="submit"
                    onClick={this.supplierSubmit}>Submit</button>
                     </div>
                    <div className="Page navigation example">
                    </div>
                  </div>
                  </div>
                    </div>)}
                    {inviteLen==0 &&( <div className="requirem">
                        <div className="text_Parts">
                          <div className="text_suppliers mb-0 pb-3">
                            <h4 className="suppliers_text pb-0">
                              Add your supplier's details below and we will
                              reach out to them on your behalf.
                            </h4>
                          </div>

                          <div className="text_Parts">
                            <div className="Supplier_Company">
                              <div className="forms">
                                <form name="form" onSubmit={this.handleSubmit}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row boxborder border_box mb-3">
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any">
                                            <div className="form-group mb-3">
                                              <label htmlFor="exampleInputEmail1">
                                                Supplier Company Name*
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control input_areas_selection"
                                                onChange={this.handleChange}
                                                value={companyName}
                                                name="companyName"
                                                id="floatingInput"
                                                placeholder="Enter Company Name"
                                              />
                                              {submitted && !companyName && (
                                                <div className="help-block">
                                                  Company Name is required
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any contact_first">
                                            <div className="form-group">
                                              <label htmlFor="exampleInputEmail1">
                                                Contact First Name*
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control input_areas_selection"
                                                onChange={this.handleChange}
                                                value={firstName}
                                                name="firstName"
                                                id="floatingInput"
                                                placeholder="Enter First Name"
                                              />
                                              {submitted && !firstName && (
                                                <div className="help-block">
                                                  First Name is required
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any mb-3">
                                            <div className="form-group">
                                              <label htmlFor="exampleInputEmail1">
                                                Contact Last Name*
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control input_areas_selection"
                                                onChange={this.handleChange}
                                                value={lastName}
                                                name="lastName"
                                                id="floatingInput"
                                                placeholder="Enter Last Name"
                                              />
                                              {submitted && !lastName && (
                                                <div className="help-block">
                                                  Last Name is required
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any mb-3">
                                            <div className="form-group">
                                              <label htmlFor="exampleInputEmail1">
                                                Contact Mobile Number*
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control input_areas_selection"
                                                onChange={this.handleChange}
                                                value={mobile}
                                                name="mobile"
                                                id="floatingInput"
                                                placeholder="Enter Mobile Number"
                                              />
                                              {submitted && !lastName && (
                                                <div className="help-block">
                                                 Mobile Number is required
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any">
                                            <div className="form-group">
                                              <label htmlFor="exampleInputEmail1">
                                                Contact Email Address*
                                              </label>
                                              <input
                                                type="email"
                                                onBlur={(text) => this.emailValidator(text)}
                                                className="form-control input_areas_selection"
                                                onChange={this.handleChange}
                                                value={email}
                                                name="email"
                                                id="floatingInput"
                                                placeholder="Enter Email Address"
                                              />
                                              {submitted && !email && (
                                                <div className="help-block">
                                                  Email Address is required
                                                </div>
                                              )}
                                              {/* <span style={{ color: "red"}}>
                                                {this.state.emailError}
                                              </span> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xxl-6 col-lg-12 col-md-12 col-12">
                                          <div className="Supplier_any contact_first">
                                            <div className="form-group">
                                              <label htmlFor="exampleInputEmail1">
                                              Industry*
                                              </label>
                                              <div className="pb4">
                                                <div className="input-group mb-3">
                                                  <span>
                                                    <i className=""></i>
                                                  </span>
                                                  <select 
                                                    name="industry"
                                                    id="industry"
                                                    placeholder="Select Industry Name"
                                                    onChange={(event) => this.handleChange(event)}
                                                    val
                                                    className="form-control input_areas_selection">
                                                      <option value="" disabled selected>Please Select Industry Name</option>
                                                      {companyIndustory?.map((item) => (
                                                        <option value={`${item.title+"-"+item.id}`} >{item.title}</option>
                                                      ))}
                                                      {submitted && !industry && (
                                                        <div className="help-block">
                                                          Industry is required
                                                        </div>
                                                      )}
                                                    </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="global_link mx-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <button className="new_button_style" type="submit">
                                    INVITE SUPPLIER
                                  </button>
                                </div>                                
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>)}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}