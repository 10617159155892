/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react"
import { authenticationService } from "../../_services/authentication"
import { history } from "../../_helpers/history"
import { Link, NavLink, useHistory } from "react-router-dom"
import config from "../../config/config.json"
import "./header.css"
import {
  getSubscriptionAuth,
  checkIsAuthenticated,
} from "../../utils/UniversalFunction"
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext"
import Switch from "react-switch"
import axios from "axios"
import swal from "sweetalert"
import { apiCall } from "../../_services/apiCall"

const ProfilePics =
"https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png"
export default class header extends Component {
  static contextType = PermissionMenuContext
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      usersData: [],
      name: "",
      profilePic: null,
      firstName: "",
      lastName: "",
      checked: false,
      role: localStorage.getItem("role"),
      otherRole: JSON.parse(localStorage.getItem("currentUser")),
    }

    this.goToPreviousPath = this.goToPreviousPath.bind(this)
    this.routeChecking = this.routeChecking.bind(this)
    this.getProfileData = this.getProfileData.bind(this)
    this.calculateTime = this.calculateTime.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(checked) {
    this.setState({ checked: checked })
    console.log(this.state.checked, "checked uncheck")
    if (this.state.checked) {
      this.context.setToggleUserRole("company")
    } else {
      this.context.setToggleUserRole("supplier")
    }
  }
  goToPreviousPath() {
    window.history.back()
  }

  logout() {
    authenticationService.logout()
    history.push("/")
    localStorage.clear()
  }
  // getProfileData

  async routeChecking() {
    let data = await checkIsAuthenticated()
    let checkCondition = data.status === false
    // console.log(checkCondition)
    if (checkCondition) {
      localStorage.clear()
      return false
    } else {
      const subscriptionAuth = getSubscriptionAuth("subscriptionAuth")
      if (subscriptionAuth === false || subscriptionAuth === undefined) {
        window.location.href = "/#/subscription_plan"
      }
      const currentUser = JSON.parse(localStorage.getItem("currentUser"))
      if (currentUser === null) {
        window.location.href = "/"
      } else {
        if (
          currentUser.data.role === "super_admin" ||
          currentUser.data.role === "sub_admin"
        ) {
          window.location.href = "/"
        }
      }
      return true
    }
  }

  async getProfileData() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"))
    const headers = {
      Authorization: `Bearer ${currentUser.data?.token}`,
      Accept: "application/json",
    }
    const { isSuccess, data } = await apiCall(
      config.API_URL + "getProfileData",
      {}
    )
    if (isSuccess) {
      localStorage.setItem("user_temp_id", JSON.stringify(data.user[0]?.id))
      // localStorage.setItem("role", JSON.stringify(data.user[0]?.role));
      // localStorage.setItem("user_temp_email", data.user[0]?.email);
      // localStorage.setItem("role", data.user[0]?.role);
      if (data.user[0]?.userCategory !== "Partner") {
        this.calculateTime(data.user[0]?.subscriptionExpiredAt)
      }
      // console.log(data?.user[0], "data.user[0]")
      let beforeThis = data?.user[0]?.profile_picture?.split(":")[0];
      this.setState({
        isLoaded: true,
        usersData: data.user,
        name: data.user[0]?.register_company_name,
        profilePic:
          data.user[0]?.profile_picture === null &&  beforeThis != "https"
            ? ProfilePics
            :  data.user[0]?.profile_picture,
        firstName: data?.user[0]?.first_name,
        lastName: data?.user[0]?.last_name,
      })
    }
    // fetch(config.API_URL + "getProfileData", { headers })
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       localStorage.setItem(
    //         "user_temp_id",
    //         JSON.stringify(result.user[0]?.id)
    //       )
    //       // localStorage.setItem("role", JSON.stringify(result.user[0]?.role));
    //       // localStorage.setItem("user_temp_email", result.user[0]?.email);
    //       // localStorage.setItem("role", result.user[0]?.role);
    //       if (result.user[0]?.userCategory !== "Partner") {
    //         this.calculateTime(result.user[0]?.subscriptionExpiredAt)
    //       }
    //       console.log(result?.user[0], "result.user[0]")
    //       this.setState({
    //         isLoaded: true,
    //         usersData: result.user,
    //         name: result.user[0]?.register_company_name,
    //         profilePic:
    //           result.user[0]?.logo === null
    //             ? ProfilePics
    //             : config.BASE_URL + result.user[0]?.logo,
    //       })
    //     },
    //     (error) => {
    //       window.location.href = "/"
    //       this.setState({
    //         isLoaded: true,
    //         error,
    //       })
    //     }
    //   )
  }

  calculateTime(endDate) {
    let url = document.URL.split("/")
    if (url[3] !== "checkout") {
      if (endDate !== null) {
        let start = Date.now() / 1000
        let end = endDate
        let dd = start.toFixed(0)
        function getMinDiff(startDate, endDate) {
          let response = false
          if (endDate < startDate) {
            response = true
          }
          return response
        }
        let diffMinutes = getMinDiff(dd, end)
        if (diffMinutes === true) {
          window.location.href = "/#/subscription_plan"
        }
      }
    }
  }

  async componentDidMount() {
    let getData = await this.routeChecking()
    let checkCondition = getData === false
    if (checkCondition) {
      window.location.href = "/"
    } else {
      this.getProfileData()
    }
  }

  sidebarOpen() {
    const div = document.getElementById("sidebar-wrapper")
    const res = div.classList.contains("active-sidebar") // true

    if (res) {
      document
        .getElementById("sidebar-wrapper")
        .classList.remove("active-sidebar")
      document.getElementById("sidebar-wrapper").style.display = "block"
      document.getElementById("sidebar-wrapper").style.zIndex = "3"
      setTimeout(() => {
        document.getElementById("sidebar-wrapper").style.marginLeft = "0rem"
      }, 200)
    } else {
      document.getElementById("sidebar-wrapper").classList.add("active-sidebar")
      document.getElementById("sidebar-wrapper").style.marginLeft = "-15rem"
      setTimeout(() => {
        document.getElementById("sidebar-wrapper").style.display = "none"
      }, 200)
    }
  }

  saveToContext = (payloadValue, typeValue) => {
    const { dispatch } = this.context
    dispatch({
      type: typeValue,
      payload: payloadValue,
    })
    console.log("payload type", payloadValue, typeValue)
  }

  SwitchUserRole = () => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"))
    const headers = {
      Authorization: `Bearer ${currentUser.data?.token}`,
      Accept: "application/json",
    }
    axios
      .post(
        `${config.API_URL}switchUser`,
        {
          switchRoleId: JSON.parse(localStorage.getItem("currentUser"))?.data
            ?.other_roles[0]?.role_id,
        },
        {
          headers,
        }
      )
      .then((response) => {
        let value = this
        swal({
          icon: "success",
          title: "Success",
          text: "User role successfully switched.",
          timer: 2000,
        })

        console.log(response?.data, "switch")
        console.log("i am clicked")
        localStorage.setItem("currentUser", JSON.stringify(response))
        localStorage.setItem("menu", JSON.stringify(response?.data?.menu))
        localStorage.setItem(
          "user_temp_id",
          JSON.stringify(response?.data?.user_id)
        )
        localStorage.setItem(
          "role",
          JSON.stringify(response?.data?.user_type_code)
        )
        localStorage.setItem("subscriptionAuth", JSON.stringify(200))

        // const menu = response?.data?.menu;
        this.saveToContext(response?.data?.menu, "Add_Menu")
        this.saveToContext(response?.data?.user_type_code, "Add_Role")
        console.log(this.props, "route history")
        // this.props.history.push('/home');
        return (window.location.href = config.baseURL + "/#/home")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    const { name, profilePic, lastName, firstName } = this.state
    const { state } = this.context
    let fullUrl = window.location.href.split("/")

    let urr = ""
    if (fullUrl[3] !== "sub_accounts") {
      urr = fullUrl.pop().toUpperCase()
    } else {
      urr = fullUrl[3]
    }
    let finalResult = urr.replaceAll("_", " ")
    if (finalResult === "SUPPLIER FAST") {
      finalResult = "SUPPLIER"
    }
    if (finalResult === "SUSTAINABLE") {
      finalResult = "SUSTAINABLE DEVELOPMENT GOALS"
    }

    if (fullUrl[3] === "checkout") {
      finalResult = "Success"
    }

    let uri = window.location.pathname.split("/")
    let path = uri[1]

    if (path === "Leadership_Governance") {
      finalResult = "Leadership & Governance"
    }
    if (path === "Cyber_Digital") {
      finalResult = "Cyber & Digital"
    }
    if (path === "Business_Model_Innovation") {
      finalResult = "Business Model & Innovation"
    }

    if (path === "suppliers_fast") {
      finalResult = "Suppliers"
    }

    return (
      <div>
        <div className="d-flex" id="wrapper">
          {/* <!-- Page content wrapper--> */}
          <div id="page-content-wrapper">
            {/* <!-- Top navigation--> */}
            <nav className="navbar navbar-expand-lg  border-bottom navclassName background topbar_esg">
              <div className="container-fluid">
                {/* <!--  <button className="desh" id="sidebarToggle">Deshboard</button> --> */}
                <div className="deahbord " id="sidebarToggle">
                  <NavLink
                    to="#"
                    className="sidebar_bar"
                    onClick={this.sidebarOpen}
                  >
                    <i className="fas fa-bars"></i>
                  </NavLink>
                  <h4 className="back_quninti back_quninti_2">
                    <a className="back_text" style={{ display: "flex" }}>
                      <span className="step_icon">
                        <i
                          onClick={this.goToPreviousPath}
                          className="far fa-long-arrow-left"
                        ></i>
                      </span>
                      {finalResult}
                    </a>
                  </h4>
                </div>

                {/* <div className="toggle__btn__styling">
                <label className="toggle__label">
                  <span className="text-capitalize">{this.context?.toggleUserRole}</span>
                  <Switch
                    onChange={this.handleChange}
                    checked={this.state.checked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#209ed1"
                    offColor="#209ed1"
                  />
                </label>
                </div> */}
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    <i className="fas fa-bars"></i>
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                      {/* <div className="form-group has-search one"> */}
                      {this.state.otherRole?.data?.other_roles.length > 0 && (
                        <button
                          onClick={this.SwitchUserRole}
                          style={{
                            marginTop: "16px",
                            marginRight: "20px",
                            background: "#209ed1",
                            color: "#fff",
                            borderRadius: "5px",
                            border: "none",
                            padding: "10px 20px",
                            cursor: "pointer",
                          }}
                        >
                          {state.role === "company"
                            ? "Go To Supplier"
                            : "GO TO Company"}
                        </button>
                      )}
                      <div className="has-search">
                        {/* <span className="fa fa-search form-control-feedback"></span> */}
                        {/* <input type="text" className="form-control" placeholder="Search..."/> */}
                      </div>
                    </li>
                    <li className="nav-item dropdown text_down">
                      <div className="image_round">
                        <img
                          src={profilePic}
                          alt="Ban"
                          onError={(e) => {
                            e.target.onerror = null
                            e.target.src =
                              "https://placeimg.com/200/300/animals"
                          }}
                          className="image--coverq"
                        />
                      </div>
                      <a
                        className="nav-link home_drop"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {name}
                        <i
                          className="fa fa-caret-down elly"
                          aria-hidden="true"
                        ></i>
                        <p className="text_p">
                          {firstName} {lastName}
                        </p>
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link to="/settings" className="dropdown-item">
                          <i className="fa fa-user"></i>
                          <span>My Profile</span>
                        </Link>
                        {/*<Link to="/Google_2FA" className="dropdown-item">
                          <i className="fa fa-lock"></i>
                          <span>Two Factor Auth</span>
                        </Link>
                        <Link to="/Setting_Billing" className="dropdown-item">
                          <i className="fa fa-dollar-sign"></i>
                          <span>Subscription</span>
                        </Link>
                        <Link to="/sub_accounts" className="dropdown-item">
                          <i className="fa fa-users"></i>
                          <span>Sub Admins</span>
                        </Link>*/}
                        <Link
                          onClick={this.logout}
                          to="/"
                          className="dropdown-item"
                        >
                          <i className="fa fa-sign-out pull-right"></i>
                          <span>Logout</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    )
  }
}

;<script></script>
