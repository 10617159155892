import config from "../../config/config.json"
import { getStore } from "../../utils/UniversalFunction"

export const commonService = {
  checkIsAuthenticated,
}

async function checkIsAuthenticated() {
  // Comment-SB- This functionality is already taken care of in Middleware.

  // let headerSet = getStore("currentUser");
  // if (headerSet === null) {
  //   let data = {};
  //   data.status = false;
  //   return data;
  // } else {
  //   const response = await fetch(
  //     `${config.API_URL}checkIsAuthenticated`,
  //     headersWithAuthBody(
  //       "POST",
  //       { token: headerSet.data.token },
  //       headerSet.data.token
  //     )
  //   );
  //   return await response.json();
  // }
  return true
}

function headersWithAuthBody(method, data, token) {
  let myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("Authorization", `Bearer ${token}`)
  let raw = JSON.stringify(data)

  let requestOptions = {
    method: method,
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }
  return requestOptions
}
