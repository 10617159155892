import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import "./App.css"
import Login from "./component/Login/login"
import OTPVerify from "./component/Login/otpverify"
import ResetPass from "./component/Login/resetpass"
import Signup from "./component/signup/signup"
import NotFoundRoute from "./component/Company Sub Admin/Pages/404"
import ScrollToTop from "./scrollToTop"
import OtpVerification from "./OtpVerification"
import NewPassword from "./component/Login/NewPassword"
import EmailVerification from "./component/Verfications/EmailVerfications"
import AdminRoute from "./AdminRoute"
import { useContext, useState } from "react"
import SupplierRoute from "./SupplierRoute"
import { PermissionMenuContext } from "./contextApi/permissionBasedMenuContext"
import SupplierSignup from "./component/signup/SupplierSignup"
import { HashRouter } from "react-router-dom"
import SignupFor from "./component/Login/companyLogin"
import Massage from "./component/Verfications/massage"

export default function App() {
  const [userRole, setUserRole] = useState(
    JSON.parse(localStorage.getItem("currentUser"))?.data?.user_type_code
  )
  const { state } = useContext(PermissionMenuContext)
  return (
    <div className="App">
      <HashRouter>
        <OtpVerification />
        <ScrollToTop />
        <Switch>
          <Route exact path="/sign_in/:id/:token" component={SignupFor}></Route>
          <Route exact path="/verify_message" component={Massage}></Route>
          <Route exact path="/" component={Login}></Route>
          <Route exact path="/Login" component={Login}></Route>
          <Route exact path="/otpverify" component={OTPVerify}></Route>
          <Route exact path="/ResetPass" component={ResetPass}></Route>
          <Route exact path="/verify/:token" component={NewPassword}></Route>
          <Route exact path="/email/verify/:token" component={EmailVerification} ></Route>
          <Route exact path="/signup/:signup?" component={Signup}></Route>
          <Route exact path="/signup/:email?/:token?" component={Signup} ></Route>
          <Route exact path="/company_invite/:email?/:token?/:countryCode?" component={Signup} ></Route>
          <Route exact path="/supplier_invite/:token?" component={SupplierSignup} ></Route>
          {state.role === "company" || "assisted_company" ? (
            <AdminRoute />
          ) : (
            <SupplierRoute />
          )}
          <Route path="*" component={NotFoundRoute} />
        </Switch>
      </HashRouter>
    </div>
  )
}
