import React, { useState } from "react";

export default function TebularInputCard({
  qualitativeStatus,
  qualitativeRemark,
  item,
  value,
  handleOnChange,
  handleKeyDown,
  handleAddRow,
}) {
  const answer2D = value && value.length > 0 ? value : [];
  return (
    <div className="table__wrapper">
      <table className="table">
        <thead>
          <tr className="option_wrapper">
            <th>
              {qualitativeStatus && (
                <div>
                  {" "}
                  Status:- {""}
                  {qualitativeStatus?.replace(/_/g, " ")}
                </div>
              )}
              {qualitativeRemark && (
                <div>
                  Auditor Remark:- {"  "}
                  {qualitativeRemark}
                </div>
              )}
            </th>
            {item?.question_detail &&
              item?.question_detail
                .filter(function (item) {
                  return item.option_type == "column";
                })
                .map((col, ind) => {
                  return <th key={ind}>{col.option}</th>;
                })}
          </tr>
        </thead>
        <tbody>
          {item?.question_detail &&
            item?.question_detail
              .filter(function (item) {
                return item.option_type == "row";
              })
              .map((row, indRow) => {
                return (
                  <tr
                    key={indRow}
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <th className="option_wrapper">{row.option}</th>
                    {item?.question_detail &&
                      item?.question_detail
                        .filter(function (item) {
                          return item.option_type == "column";
                        })
                        .map((col, indCol) => {
                          // const value = `${indRow}-${indCol}`;
                          {
                            console.log(col.option, row.option, "sapidblue");
                          }
                          const isEmail =
                            col.option.includes("E mail") ||
                            row.option.includes("E mail");
                          const isDate =
                            col.option.includes("date") ||
                            row.option.includes("date");
                          const isYear =
                            col.option.includes("year") ||
                            row.option.includes("year");
                            const isContact =
                            col.option.includes("Contact") ||
                            row.option.includes("Contact") ;
                          const isNumber =
                            col.option.includes("%") ||
                            row.option.includes("%") ||                          
                            col.option.includes("Number") ||
                            row.option.includes("Number") ||
                            col.option.includes("Total") ||
                            row.option.includes("Total") ||
                            col.option.includes("No.") ||
                            row.option.includes("No.")||
                            col.option.includes("Rate") ||
                            row.option.includes("Rate");;

                          let inputType = "text";
                          if (isNumber ) {
                            inputType = "number";
                          } else if (isDate || isYear) {
                            inputType = "date";
                          } else if (isEmail) {                           
                            inputType = "email";
                          } else if(isContact) {
                            inputType ="tel";
                          }
                          const value =
                            (answer2D &&
                              answer2D[indRow] &&
                              answer2D[indRow][indCol]) ||
                            "";
                          return (
                            <td key={indCol}>
                              <input
                                value={value}
                                type={inputType}
                                onChange={(event) => {
                                  const tempObj = {
                                    ...item,
                                    indexRow: indRow,
                                    indexCol: indCol,
                                  };
                                  handleOnChange(
                                    tempObj,
                                    event,
                                    item?.questionType
                                  );
                                }}
                                onKeyDown={(event) => {
                                  const tempObj = {
                                    ...item,
                                    indexRow: indRow,
                                    indexCol: indCol,
                                  };
                                  handleKeyDown(tempObj, event);
                                }}
                              />
                            </td>
                          );
                        })}
                  </tr>
                );
              })}
        </tbody>
      </table>
      {item?.add_row == 1 ? (
        <button className="new_button_style mb-3" onClick={handleAddRow}>
          Add new row
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
