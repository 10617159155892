import React,{ useEffect,useState } from 'react'
import "./home.css"

const CircularProgressBar = (props) => {
console.log(props.score);
    const [progress,setGoProgress] = useState(props.score);
    const progresss = props.score

    useEffect(() => {
        let yb = { id: function (str) { return document.getElementById(str) } };
        function showSliderValue() { yb.id('slidervalue').innerHTML = yb.id('slider').value + ''; }
        showSliderValue();
        setProgress();
        setGoProgress(props?.score)

        yb.id('slider').oninput = function () { showSliderValue(); setProgress() };
        yb.id('slider').onchange = function () { showSliderValue(); setProgress() };


        function setProgress() {
            let radius = yb.id('progress').getAttribute('r');
            let circumference = 2    * Math.PI * radius;

            let progress_in_percent = yb.id('slider').value;
            let progress_in_pixels = circumference * (1000 - progress_in_percent) / 1000;
            yb.id('progress').style.strokeDashoffset = progress_in_pixels + 'px';

            if (yb.id('slider').value < 250) {
                yb.id('progress').style.stroke = '#F2E856';
                yb.id('slidervalue').style.color = '#F2E856';
            }
            else if (yb.id('slider').value >= 750) {
                yb.id('progress').style.stroke = '#34BAB7';
                yb.id('slidervalue').style.color = '#34BAB7';
            }
            else {
                yb.id('progress').style.stroke = '#F4B450';
                yb.id('slidervalue').style.color = '#F4B450';
            }
        }
    },[setGoProgress,props.score])
    return (
        <>
            <div className="cssprogresscontainer">
                <div id="svgcontainer">
                    <svg height="300" width="300" id="svg">
                        <circle id="progressbg" cx="150" cy="150" r="120" strokeWidth="29" fill="transparent" strokeDasharray="753.9822368615503" />
                        <circle id="progress" cx="150" cy="150" r="120" strokeWidth="30" fill="transparent" strokeDasharray="753.9822368615503" />
                    </svg>
                    <div id="slidervalue"></div>
                </div>

                <br />
                <input className='d-none' type="range" id="slider" readOnly min="0" value={(progress > 0) ? progress : progresss} max="1000" />
                <div className='ponting_donation'>
                    <div className='above_average'><span className='radio_check_1'>.</span>Above Average</div>
                    <div className='above_average' ><span className='radio_check_2'>.</span>Average</div>
                    <div className='above_average'><span className='radio_check_3'>.</span>Below Average</div>
                </div>
            </div>
        </>
    )
}

export default CircularProgressBar