import React, { createContext, useEffect, useReducer, useState } from "react";
import { reducer } from "./MenuContextReducers";

const initialState = {
  menus: JSON.parse(localStorage.getItem("menu")) || [],
  role: JSON.parse(localStorage.getItem("role")),
};

export const PermissionMenuContext = createContext(initialState);

export default function PermissionBasedMenuProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [UserMenuList,setUserMenuList] = useState(state?.menus);
  const [userPermissionList,setUserPermissionList] = useState([]);

  useEffect(() =>{
   const getPermissions = (UserMenuList) => {
    setUserPermissionList([]);
    const permissionsArray = [];
    UserMenuList.forEach(item => {
      permissionsArray.push(...item.permissions); // push main menu permissions
      item.sub_menu.forEach(subItem => {
        permissionsArray.push(...subItem.permissions); // push sub-menu permissions
      });
    });
    setUserPermissionList(permissionsArray);
   }
    getPermissions(UserMenuList);
  },[])

  return (
    <PermissionMenuContext.Provider value={{ state, dispatch ,userPermissionList}}>
      {children}
    </PermissionMenuContext.Provider>
  );
}
