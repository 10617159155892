import config from "../../../config/config.json";
import { getStore } from "../../../utils/UniversalFunction";
import swal from "sweetalert";

export const KpiService = {
  getKpis,
  deleteKPI,
  updateSubscriptionStatus,
  createKpi,
  getKPIByID,
  updateKPI,
};

async function getKPIByID(uuid) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}getKPIByID/${uuid}`,
    headersWithAuthNew("GET", headerSet.data.token)
  );
  const data = await response.json();
  return data;
}

async function getKpis(type) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}getKpi?type=${type}`,
    headersWithAuthNew("GET", headerSet.data.token)
  );
  const data = await response.json();
  return data;
}

async function deleteKPI(uuid) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}deleteKPI/${uuid}`,
    headersWithAuthNew("DELETE", headerSet.data.token)
  );
  const data = await response.json();
  handleResponse(data);
}

async function createKpi(userData) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}createKpi`,
    headersWithAuthBody("POST", userData, headerSet.data.token)
  );
  const data = await response.json();
  handleResponse(data);
}

async function updateKPI(userData) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}updateKpi`,
    headersWithAuthBody("POST", userData, headerSet.data.token)
  );
  const data = await response.json();
  handleResponse(data);
}

async function updateSubscriptionStatus(userData) {
  let headerSet = getStore("currentUser");
  const response = await fetch(
    `${config.API_URL}billing/subscription/update`,
    headersWithAuthBody("POST", userData, headerSet.data.token)
  );
  const data = await response.json();
  handleResponse(data);
}

function headersWithAuthBody(method, data, token) {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  let raw = JSON.stringify(data);

  let requestOptions = {
    method: method,
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return requestOptions;
}

function headersWithAuthNew(method, token) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  let requestOptions = {
    method: method,
    headers: myHeaders,
    redirect: "follow",
  };
  return requestOptions;
}

// function headersWithAuth(method, userData, auth) {
//   return {
//     method: method,
//     headers: auth,
//     body: JSON.stringify(userData),
//   };
// }

function handleResponse(response) {
  return response.statusCode === 200
    ? successAlert(response.customMessage, "", "success")
    : alert(response.customMessage, "Please try again later..!", "error");
}

function alert(message, message2, statusCode) {
  swal(message, message2, statusCode);
  return false;
}

function successAlert(message, message2, statusCode) {
  swal({
    icon: 'success',
    title: message2,
    text: message,
    timer: 2000
  })
  return true;
}
