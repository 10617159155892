import React, { Component } from "react";

export default class social_consideration extends Component {
  render() {
    return (
      <div>
        {/* <!-- end panel 1 --> */}
        <div id="panel2" className="panel">
          <div className="table_well">
            <p className="actual">
              <strong>Human Assets - </strong> Business risks of an increasingly employee-driven job market, market phases such as the Great Resignation, and the ongoing “War for Talent” are very likely to rise.
            </p>
            <p className="actual">
              <strong>Safety Implications - </strong>With the increasing complexities in supply chains, ensuring that the products or services of a business do not pose a threat to customer safety is ever-important. The costs are also highly contingent to changing regulations such as the Supply Chain Due Diligent Act, recently passed by the German Parliament.
            </p>
            <p className="actual">
              <strong>Transitions in Customer Behaviour - </strong>A social change that could significantly risk the acceptance of your very product or service offering by the open market.
            </p>
            <p className="actual">
              <strong>Demographic Transitions - </strong>Changes in the demographies of different economies can either shrink or expand your potential consumer base, altering the growth trajectory of your business.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
